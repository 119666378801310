<template>
  <div class="container">
    <div class="citizen-service">
      <div class="citizen-item">
        <h2 class="citizen-title">Hurmatli fuqaro, murojaatingiz uchun raxmat!</h2>
        <p class="citizen-text">Sizning arizangiz O'zbekiston Respublikasi Kambag'allikni qisqartirish va bandlik vazirligida ro‘yxatga olindi.</p>
        <h5>Siz <b>{{ form.c_date?form.c_date.split('-').reverse().join('.'):'' }}</b> kuni soat <b>{{ form.c_time }}</b> da  <b>{{ form.department_name }}</b> bo'limiga  <b>{{ form.service_name }}</b> yuzasidan qabulga yozildingiz</h5>
       
        <a href="/" class="btn homeBtn"><i class="lni-arrow-left" />&nbsp; Bosh sahifaga qaytish</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>

<style>

</style>
