<template>
  <application-leave v-if="form.step == 1" :form="form" />
  <div v-if="form.step == 2" class="container application-form bg-white ">
    <el-card>
      <div class="mb-5">
        <el-form
          ref="formRef"
          :model="form"
          label-width="120px"
          label-position="top"
          status-icon
          :rules="rules"
          class="top-label-custom"
        >
          <h3 class="section-title">{{ $t("Shaxsga oid ma'lumotlar") }}</h3>
          <div v-loading="loading">
            <el-row>
              <el-col :span="8" :xs="24" :sm="12" :lg="8" :xl="8">
                <el-form-item :label="$t('Pasport seriya va raqamingiz')" prop="citizen_passport">
                  <el-input ref="pasportForm" v-model="form.citizen_passport" v-maska="'AA#######'" size="large" placeholder="AA0000000" />
                </el-form-item>
              </el-col>
              <el-col :span="8" :xs="24" :sm="12" :lg="8" :xl="8">
                <el-form-item :label="$t('JShShIRni kiriting')" prop="pin">
                  <el-input ref="bDateForm" v-model="form.pin" v-maska="'##############'" size="large" placeholder="00000000000000" />
                </el-form-item>
              </el-col>
              <el-col :span="8" :xs="24" :sm="12" :lg="8" :xl="8">
                <el-form-item :label="$t('Telefon raqami')" prop="phone">
                  <el-input v-model="form.phone" v-maska="'+998#########'" disabled size="large" placeholder="+998900000000" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="16" :xs="24" :sm="12" :lg="8" :xl="16">
                <el-form-item :label="$t('F.I.O.')" prop="full_name">
                  <el-input v-model="form.full_name" disabled size="large" placeholder="FIO" />
                </el-form-item>
              </el-col>
              <el-col :span="8" :xs="24" :sm="12" :lg="8" :xl="8">
                <el-form-item :label="$t('Email')">
                  <el-input v-model="form.email" type="email" size="large" placeholder="pochta@gmail.com" />
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <h3 class="section-title">{{ $t('Qabul masalasi') }}</h3>
          <el-row>           
            <el-col :xs="24" :sm="12" :lg="12" :xl="12">
              <el-form-item :label="$t('Bo‘limini tanlang')" prop="department_id">
                <el-select v-model="form.department_id" class="w-100" size="large" @change="setIdName(form.department_id, departments, 'department_name')">
                  <el-option
                    v-for="item in departments"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :lg="12" :xl="12">
              <el-form-item :label="$t('Xizmat turini tanlang')" prop="service_id">
                <el-select v-model="form.service_id" class="w-100" size="large" @change="setIdName(form.service_id, services, 'service_name')">
                  <el-option
                    v-for="item in services"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
              <el-form-item :label="$t('Qabulga yozilish sanasi')" prop="c_date">
                <el-date-picker
                  v-model="form.c_date"
                  format="DD.MM.YYYY"
                  value-format="YYYY-MM-DD"
                  size="large"
                  type="date"
                  :picker-options="{ firstDayOfWeek: 1 }"
                  placeholder="Sanani tanlang"
                  :disabled-date="disabledDate"
                  :shortcuts="shortcuts"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
              <el-form-item :label="$t('Qabulga yozilish vaqti')" prop="c_time">
                <el-time-picker 
                  v-model="form.el_time"
                  :disabled-seconds="disabledSeconds"
                  format="HH:mm"
                  size="large" 
                  placeholder="Vaqtni tanlang" 
                  confirm-title="ok"
                />
              </el-form-item>
            </el-col>
          </el-row>         
        </el-form>
        <a            
          class="btn saveButton float-right"
          @click="save()"
        >Saqlash</a>
      </div>
    </el-card>
  </div>
  <AppointmentNotice v-if="form.step == 3" :form="form" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ElMessage, ElNotification } from 'element-plus'
import ApplicationLeave from '../application/application-leave.vue'
import AppointmentNotice from '../message/appointment-notice.vue'
export default {
  name: 'ApplicationCreate',
  components: { ApplicationLeave, AppointmentNotice },
  data() {
    return {
      regions: [],
      districts: [],
      active_positions: [],
      imageUrl: '',
      loading: false,
      saveLoading: false,
      fileList: [],
      validated: false,
      form: {
        step: 1,
        citizen_passport: null,
        pin: null,
        phone: null,
        full_name: null,
        email: null,
        // 
        department_id: null,
        service_id: null,
        c_date: null,
        c_time: null,
        el_time: null,
        department_name: '',
        service_name: ''
      },
      categories: [
        { id: 1, name: 'Majburiy mahnat', disabled: false },
        { id: 2, name: 'Ish haqi masalasi', disabled: false },
        { id: 3, name: 'Noqonuniy ishdan bushatish', disabled: false },
        { id: 4, name: 'Ishga qabul qilmaslik', disabled: false },
        { id: 5, name: 'Ishlash sharoitlari yomon', disabled: false },
        { id: 6, name: 'Boshqa', disabled: false },
        { id: 7, name: 'Tushuntirish olish' }
      ]      
    }
  },
  computed: {
    ...mapGetters({ regions: 'region/GET_REGIONS', services: 'appointment/GET_SERVICES', departments: 'appointment/GET_DEPARTMENTS' }),
    mediaUrl() {
      return 'https://dmi.mehnat.uz/api/v1/uploadFile/'
    },
    rules() {
      return {
        citizen_passport: [{ required: true, message: this.$t("Maydonni to'ldiring"), trigger: 'change' }],
        pin: [{ required: true, message: this.$t("Maydonni to'ldiring"), trigger: 'change' }],
        full_name: [{ required: true, message: this.$t("Maydonni to'ldiring"), trigger: 'change' }],
        department_id: [{ required: true, message: this.$t("Maydonni to'ldiring"), trigger: 'change' }],
        service_id: [{ required: true, message: this.$t("Maydonni to'ldiring"), trigger: 'change' }],
        c_date: [{ required: true, message: this.$t("Maydonni to'ldiring"), trigger: 'change' }],
        c_time: [{ required: true, message: this.$t("Maydonni to'ldiring"), trigger: 'change' }],
        phone: [{ required: true, message: this.$t("Maydonni to'ldiring"), trigger: 'change' }]
      }
    },
    isBirthDateFull() {
      return this.form.pin && this.form.pin.length === 14
    },
    isPassportFull() {
      return this.form.citizen_passport.length === 9
    }
  },
  watch: {
    'form.citizen_passport'(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        if (this.isPassportFull) {
          if (this.isBirthDateFull) {
            this.getPassportInfo()
          } else {
            this.$refs.bDateForm.focus()
          }
        }
      }
    },
    'form.pin'(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        if (this.isBirthDateFull) {
          if (this.isPassportFull) {
            this.getPassportInfo()
          } else {
            this.$refs.pasportForm.focus()
          }
        }
      }
    }
  },
  mounted() {
    this.fetchRegions()
    this.getServices()
    this.getDepartments()
  },
  methods: {
    ...mapActions({ fetchRegions: 'region/regions', fetchCities: 'region/districts', checkPassportAdmission: 'appointment/checkPassportAdmission', getServices: 'appointment/getServices', getDepartments: 'appointment/getDepartments', store: 'appointment/store' }),
    validateForm() {
      this.$refs['formRef'].validate((valid) => {
        this.validated = valid
      })
      return this.validated
    },
    getDistrict(region_id, addres) {
      if (addres === 1) {
        this.form.rev_citizen_city_id = null
      } else if (addres === 2) {
        this.form.city_id = null        
      }
      this.fetchCities(region_id)
        .then(data => {
          this.districts = data.data
        })
    },
    getPassportInfo() {
      const data = {
        passport: this.form.citizen_passport,
        pin: this.form.pin
      }
      this.loading = true
      this.checkPassportAdmission(data)
        .then(res => {
          if (res.success) {
            this.form.full_name = [res.details.sname, res.details.fname].join(' ')            
          } else {
            this.clearPassportInfo()
            ElMessage({
              type: 'error',
              message: res.data.message || `Fuqaro topilmadi`
            })
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          ElMessage({
            type: 'error',
            message: `Fuqaro topilmadi`
          })
          this.clearPassportInfo()
          this.loading = false
        })
        .finally(() => {          
          this.loading = false
        })
    },
    // save
    save() {
      if (this.validateForm()) {
        this.saveLoading = true
        this.store(this.form)
          .then(res => {
            if (res.success) {
              ElNotification({
                title: 'Yuborildi',
                message: 'Muvaffaqiyatli yuborildi',
                type: 'success'
              })
              this.form.step = 3
            } else {
              ElMessage({
                type: 'error',
                message: `Ma'lumot to'ldirishda xatolik`
              })
            }
            this.saveLoading = false
          })
          .catch(err => {
            console.log(err)
            ElMessage({
              type: 'error',
              message: `Ma'lumot to'ldirishda xatolik`
            })
            this.saveLoading = false
          })
          .finally(() => {          
            this.saveLoading = false
          })
      } else {
        ElNotification({
          title: 'Xatolik',
          message: "Maydonlarni to'ldiring",
          type: 'error'
        })
      }
    },
    clearPassportInfo() {
      this.form.full_name = null
      this.form.citizen_address = null
      this.form.birth_date = null
      this.form.region_id = null   
      this.form.city_id = null
      this.form.rev_citizen_company_address = null
      this.form.company_name = null
      this.form.company_phone = null
      this.form.company_representative = null
    },
    disabledSeconds(hour, minute) {
      // console.log();
      this.form.c_time = String(hour + ':' + minute + ':00')
      return this.makeRange(1, 59)
    },
    setIdName(id, array, model) {
      if (array && array.length) {
        array.forEach(el => {
          if (el.id === id) {
            this.form[model] = el.name
          }
        })      
      }
    },
    makeRange(start, end) {
      const result = []
      for (let i = start; i <= end; i++) {
        result.push(i)
      }
      return result
    }
  }

}
</script>

<style>

</style>
